import classNames from 'classnames';

import PropTypes from 'prop-types';
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { createPortal } from 'react-dom';

import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import i18n from '../../i18n';
import Icon from '../Icon';
import styles from './DatePickerV2.module.scss';

import moment from 'moment';
import { isolateTabs } from '../../utils/a11y';
import { dataLayerPush } from '../../utils/datalayer';
import Button from '../Button';

import {
    ACTION_TYPES,
    EventListPageContext,
} from '../../containers/EventListPage/EventListPage';

const monthTranslations = {
    January: 'Januari',
    February: 'Februari',
    March: 'Mars',
    April: 'April',
    May: 'Maj',
    June: 'Juni',
    July: 'Juli',
    August: 'Augusti',
    September: 'September',
    October: 'Oktober',
    November: 'November',
    December: 'December',
};

const DatePickerV2 = (props) => {
    const { state: contextState, dispatch } = useContext(EventListPageContext);

    const [state, setState] = useState({
        startDate: contextState.dateFrom ?? null,
        endDate: contextState.dateTo ?? null,
        focusedInput: 'startDate',
        expanded: false,
        dateTitle: i18n.t('DatePicker.when'),
        isSwedishSite: false,
    });

    // special date is when someone clicks 'today', 'this weekend' etc
    const [specialDate, setSpecialDate] = useState();
    const [activeWhenButton, setActiveWhenButton] = useState('');
    const [isClientSide, setIsClientSide] = useState(false);

    useEffect(() => {
        // Set dates initially if there are defined in the URL
        if (contextState.dateFrom && contextState.dateTo) {
            updateDates(
                moment(contextState.dateFrom),
                moment(contextState.dateTo)
            );
        }
    }, []);

    const modalRef = useRef(null);
    const modalRefDesktop = useRef(null);
    const buttonRef = useRef(null);
    const qlRef = useRef(null);

    const updateDates = useCallback(
        (startDate, endDate, focusedInput = 'startDate') => {
            let trackDate = moment(startDate).format('YYYY-MM-DD');
            if (endDate) {
                trackDate = `${trackDate}/${moment(endDate).format(
                    'YYYY-MM-DD'
                )}`;
            }
            const dateTitleList = [moment(startDate).format('DD MMM')];
            if (endDate) {
                dateTitleList.push(moment(endDate).format('DD MMM'));
            }
            setState((prev) => ({
                ...prev,
                startDate,
                endDate,
                focusedInput: focusedInput,
                dateTitle: dateTitleList.join(' - '),
            }));
        },
        [setState]
    );

    const handleClose = () => {
        if (state.expanded) {
            setState((prev) => ({
                ...prev,
                expanded: false,
            }));
            buttonRef.current.focus();
        }
    };
    const handleTabKey = (e) => {
        if (state.expanded) {
            isolateTabs(e, modalRef.current);
        }
    };
    const keyListenersMap = new Map([
        [27, handleClose],
        [9, handleTabKey],
    ]);
    const keyListener = (e) => {
        const listener = keyListenersMap.get(e.keyCode);
        return listener && listener(e);
    };

    useEffect(() => {
        if (!isClientSide) {
            setIsClientSide(true);
        }
    }, []);

    useEffect(() => {
        const currentDomain = window.location.hostname;
        const isSwedishSite = currentDomain.includes('.se');
        setState((prev) => ({
            ...prev,
            isSwedishSite,
        }));

        document.addEventListener('keydown', keyListener);

        return () => {
            document.removeEventListener('keydown', keyListener);
        };
    }, []);

    useEffect(() => {
        const handleCloseOutsideClick = (e) => {
            const classesWithContainer = [...e.target.classList].filter(
                (className) =>
                    className.includes('DatePickerV2__DesktopContainer') ||
                    className.includes(styles['Backdrop'])
            );
            if (state.expanded && classesWithContainer.length > 0) {
                handleClose();
            }
        };

        document.addEventListener('click', handleCloseOutsideClick);

        return () => {
            document.removeEventListener('click', handleCloseOutsideClick);
        };
    }, [state.expanded]);

    useEffect(() => {
        state.expanded
            ? document.body.classList.add(
                  styles['DatePickerV2__LockBodyScroll']
              )
            : document.body.classList.remove(
                  styles['DatePickerV2__LockBodyScroll']
              );

        return () => {
            document.body.classList.remove(
                styles['DatePickerV2__LockBodyScroll']
            );
        };
    }, [state.expanded]);

    const handleExpand = () => {
        setState((prev) => ({
            ...prev,
            expanded: !state.expanded,
        }));
    };

    const getDateOfWeekday = (refday) => {
        const days = {
            monday: 1,
            tuesday: 2,
            wednesday: 3,
            thursday: 4,
            friday: 5,
            saturday: 6,
            sunday: 0,
        };

        if (!days.hasOwnProperty(refday)) {
            throw new Error(
                `${refday} is not listed in ${JSON.stringify(days)}`
            );
        }

        let currDate = new Date();
        const currTimestamp = currDate.getTime();
        const triggerDay = days[refday];
        let dayMillisecondsDiff = 0;
        const dayInMilliseconds = 1000 * 60 * 60 * 24;

        // Add a day to dayMillisecondsDiff as long as the desired refday is not reached
        while (currDate.getDay() !== triggerDay) {
            dayMillisecondsDiff += dayInMilliseconds;
            currDate = new Date(currDate.getTime() + dayInMilliseconds);
        }

        return new Date(currTimestamp + dayMillisecondsDiff);
    };

    const handleWhenClick = (when) => {
        let startDate, endDate;

        switch (when) {
            case 'today':
                const currentDate = moment(new Date());
                startDate = currentDate;
                endDate = currentDate;
                setSpecialDate('idag');
                break;

            case 'tomorrow':
                let tomorrowDate = new Date();
                tomorrowDate.setDate(tomorrowDate.getDate() + 1);
                tomorrowDate = moment(tomorrowDate);
                startDate = tomorrowDate;
                endDate = tomorrowDate;
                setSpecialDate('imorgon');
                break;

            case 'thisWeekend':
                startDate = moment(getDateOfWeekday('saturday'));
                endDate = moment(getDateOfWeekday('sunday'));
                setSpecialDate('i helgen');
                break;

            case 'nextWeek':
                const thisSunday = getDateOfWeekday('sunday');
                let nextMonday = new Date(thisSunday);
                nextMonday.setDate(thisSunday.getDate() + 1);
                let nextSunday = new Date(nextMonday);
                nextSunday.setDate(nextMonday.getDate() + 6);
                startDate = moment(nextMonday);
                endDate = moment(nextSunday);
                setSpecialDate('nästa vecka');
                break;
        }

        updateDates(startDate, endDate);
        setActiveWhenButton(when);
    };

    useEffect(() => {
        if (!contextState.dateFrom || !contextState.dateTo) {
            clearDates();
        }
    }, [contextState.dateFrom, contextState.dateTo]);

    const clearDates = () => {
        setState((prev) => ({
            ...prev,
            startDate: null,
            endDate: null,
            focusedInput: 'startDate',
            dateTitle: i18n.t('DatePicker.when'),
        }));
        setActiveWhenButton('');
    };

    const handleOnDatesChange = ({ startDate, endDate }) => {
        if (startDate < state.startDate) {
            // Reset startDate if the clicked date comes before the current startDate
            updateDates(startDate, null, 'endDate');
        } else {
            updateDates(startDate, endDate);
        }
        setActiveWhenButton('');
    };

    const closeCalender = () => {
        setState((prev) => ({ ...prev, expanded: false }));
    };

    const datesSelected = () => {
        let trackDate = moment(state.startDate).format('YYYY-MM-DD');
        if (state.endDate) {
            trackDate = `${trackDate}/${moment(state.endDate).format(
                'YYYY-MM-DD'
            )}`;
        }

        dataLayerPush({
            event: 'calendar',
            filterdate: specialDate || trackDate,
        });
        setSpecialDate(null);
        dispatch({
            type: ACTION_TYPES.CHANGE_DATES,
            startDate: state.startDate,
            endDate: state.endDate,
        });
        dispatch({
            type: ACTION_TYPES.SHOW_SEARCH_RESULTS,
            showSearchResults: true,
        });

        closeCalender();
    };

    const CalendarWrapper = ({ children }) => (
        <>
            <div className={styles['DatePickerV2__Top']}>
                <div
                    className={classNames(
                        'DatePickerV2__Calendar',
                        styles['DatePickerV2__Calender'],
                        {
                            [styles['DatePickerV2__Calender--Expanded'] +
                            ' DatePickerV2__Calender--Expanded']:
                                state.expanded,
                        }
                    )}>
                    <TopControls />
                    {children}
                </div>
            </div>
            <BottomControls />
        </>
    );

    const TopControls = () => (
        <div className={styles['DatePickerV2__ControlsContainer']}>
            <div className={styles['DatePickerV2__WhenButtonsContainer']}>
                {[
                    ['today', i18n.t('DatePicker.whenOptions.today')],
                    ['tomorrow', i18n.t('DatePicker.whenOptions.tomorrow')],
                    [
                        'thisWeekend',
                        i18n.t('DatePicker.whenOptions.thisWeekend'),
                    ],
                    ['nextWeek', i18n.t('DatePicker.whenOptions.nextWeek')],
                ].map(([when, translationString]) => (
                    <button
                        key={when}
                        className={classNames(
                            styles['DatePickerV2__WhenButton'],
                            {
                                [styles['DatePickerV2__WhenButton--Active']]:
                                    activeWhenButton === when,
                            }
                        )}
                        onClick={() => handleWhenClick(when)}>
                        {translationString}
                    </button>
                ))}
            </div>
            <button
                autoFocus
                className={styles['DatePickerV2__Close']}
                onClick={() => {
                    setState((prev) => ({
                        ...prev,
                        expanded: !state.expanded,
                    }));
                }}>
                <span className="sr-only">{i18n.t('Modal.close')}</span>
                <Icon type="close" color="black" size="large" />
            </button>
        </div>
    );

    const BottomControls = () => (
        <div className={styles['DatePickerV2__Bottom']}>
            {(!!state?.startDate || !!state?.endDate) && (
                <button
                    className={styles['DatePickerV2__ClearButton']}
                    onClick={clearDates}
                    aria-label={i18n.t('DatePickerV2.resetCategoriesAria')}>
                    <Icon
                        type={'trash'}
                        size={'fill'}
                        modifiers={[styles['DatePickerV2__ClearIcon']]}
                        color={'black800'}
                    />
                    {i18n.t('DatePicker.clearDates')}
                </button>
            )}
            <Button
                text={i18n.t('DatePicker.done')}
                type="primary"
                modifiers={[styles['DatePickerV2__DoneButton']]}
                onClick={datesSelected}
            />
        </div>
    );

    const dateRangePickerProps = {
        startDate: state.startDate,
        startDateId: 'start_date_id',
        endDate: state.endDate,
        endDateId: 'end_date_id',
        initialVisibleMonth: moment,
        onDatesChange: handleOnDatesChange,
        focusedInput: state.focusedInput,
        onFocusChange: (focusedInput) => {
            if (!focusedInput) {
                return;
            }
            setState((prev) => ({ ...prev, focusedInput: focusedInput }));
        },
        keepOpenOnDateSelect: true,
        noBorder: true,
        daySize: 40,
        hideKeyboardShortcutsPanel: true,
        renderMonthElement: (date) => {
            const month = date.month.format('MMMM');
            const translatedMonth = state.isSwedishSite
                ? monthTranslations[month]
                : month;
            const year = date.month.format('YYYY');
            return `${translatedMonth} ${year}`;
        },
        displayFormat: 'DD/MM/YY',
    };

    const calendarIconModifiers = [
        styles['DatePickerV2__ToggleIcon'],
        state.expanded ? styles['DatePickerV2__ToggleIcon--Active'] : null,
    ];

    const chevronIconModifiers = [
        styles['DatePickerV2__ToggleIcon--Chevron'],
        ...calendarIconModifiers,
    ];

    return (
        <>
            <div ref={qlRef} className={styles['DatePickerV2__QuickLinks']}>
                <button
                    ref={buttonRef}
                    className={classNames(
                        styles['DatePickerV2__ToggleButton'],
                        {
                            [styles['DatePickerV2__ToggleButton--Active']]:
                                state.expanded,
                        }
                    )}
                    onClick={() => handleExpand()}>
                    <Icon
                        type="calender"
                        color="colorGray40"
                        size="large"
                        iconModifiers={['DatePicker']}
                        modifiers={calendarIconModifiers}
                    />
                    <span className={styles['DatePickerV2__ToggleButtonTitle']}>
                        <span className="sr-only">
                            {i18n.t('DatePicker.selectDate')}
                        </span>
                        {state.dateTitle}
                    </span>
                    <Icon
                        type="chevron"
                        color="colorGray40"
                        size="large"
                        modifiers={chevronIconModifiers}
                    />
                </button>
            </div>
            <div
                className={classNames(styles['DatePickerV2__ModalContainer'], {
                    [styles['DatePickerV2__ModalContainer--Active']]:
                        state.expanded,
                    [styles['DatePickerV2__ModalContainer--Hide']]:
                        !state.expanded,
                })}>
                <div
                    ref={modalRef}
                    className={classNames(
                        styles['DatePickerV2'],
                        styles['DatePickerV2--Mobile'],
                        {
                            [styles['DatePickerV2--Expanded']]: state.expanded,
                        }
                    )}>
                    {state.expanded && (
                        <>
                            <div className={styles['DatePickerV2__Top']}>
                                <div
                                    className={classNames(
                                        'DatePickerV2__Calendar',
                                        styles['DatePickerV2__Calender'],
                                        {
                                            [styles[
                                                'DatePickerV2__Calender--Expanded'
                                            ] +
                                            ' DatePickerV2__Calender--Expanded']:
                                                state.expanded,
                                        }
                                    )}>
                                    <TopControls />
                                    <DateRangePicker
                                        {...dateRangePickerProps}
                                        numberOfMonths={12}
                                        orientation="vertical"
                                    />
                                </div>
                            </div>
                            <BottomControls />
                        </>
                    )}
                </div>
                {isClientSide &&
                    state.expanded &&
                    createPortal(
                        <div className={styles['Backdrop']} />,
                        document.body
                    )}
                {isClientSide &&
                    createPortal(
                        <div
                            className={classNames(
                                styles['DatePickerV2__DesktopContainer'],
                                {
                                    [styles[
                                        'DatePickerV2__DesktopContainer--Expanded'
                                    ]]: state.expanded,
                                }
                            )}>
                            <div
                                ref={modalRefDesktop}
                                className={classNames(
                                    styles['DatePickerV2'],
                                    styles['DatePickerV2--Desktop'],
                                    {
                                        [styles['DatePickerV2--Expanded']]:
                                            state.expanded,
                                    }
                                )}>
                                {state.expanded && (
                                    <>
                                        <div
                                            className={
                                                styles['DatePickerV2__Top']
                                            }>
                                            <div
                                                className={classNames(
                                                    'DatePickerV2__Calendar',
                                                    styles[
                                                        'DatePickerV2__Calender'
                                                    ],
                                                    {
                                                        [styles[
                                                            'DatePickerV2__Calender--Expanded'
                                                        ] +
                                                        ' DatePickerV2__Calender--Expanded']:
                                                            state.expanded,
                                                    }
                                                )}>
                                                <TopControls />
                                                <DateRangePicker
                                                    {...dateRangePickerProps}
                                                    numberOfMonths={2}
                                                />
                                            </div>
                                        </div>
                                        <BottomControls />
                                    </>
                                )}
                            </div>
                        </div>,
                        document.body
                    )}
            </div>
        </>
    );
};

DatePickerV2.propTypes = {
    onDateChange: PropTypes.func,
};

DatePickerV2.defaultProps = {
    onDateChange: () => null,
    startDate: null,
    endDate: null,
};

export default DatePickerV2;
