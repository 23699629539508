import React, { useContext } from 'react';
import { EventListPageContext } from "../../containers/EventListPage/EventListPage";

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import DatePickerV2 from "../DatePickerV2";
import styles from './EventFilter.module.scss';

const EventFilter = () => {
    const {state, dispatch} = useContext(EventListPageContext)
    const openCalendar = () => {
        dispatch({
            type: 'openCalendar',
            payload: true,
        })
    }

    return (
        <>
            <div className={styles['EventFilter']}>
                    <div className={styles['EventFilter__DatePickerContainer']}>
                        <DatePickerV2 />
                    </div>
            </div>
        </>
        )
};

EventFilter.propTypes = {};

EventFilter.defaultProps = {};

export default EventFilter;
